<template>
  <section class="About">
    <article>
      <h1>
        <strong>
          <strong><span class="fine">FINE</span>&nbsp;<span class="art">ART<span class="comma">,</span></span></strong>
          <strong><span class="made">MADE</span></strong>
          <strong><span class="with">WITH</span>&nbsp;<span class="code">CODE</span><span class="fullstop"></span></strong>
        </strong>
      </h1>
      <div class="biography">
        <div class="primary">
          <p>
            I brew a mean beer and cook a fine meal. <br>
            I love bicycles, birdlife, and malted barley. <br>
            I proudly call New Zealand home, <br>
            it's my favourite place.
          </p>
        </div>
        <div class="secondary">
          <hr />
          <p>With roots in <a href="https://kellymilligan.nz" target="_blank"><span>front-end and creative development</span></a> in the browser, I translate practical code knowledge into algorithmic and generative art; for-print, in-motion, and fully interactive.</p>
          <p>I aim for organic texture and bold colour. <br>I love results with unexpected form and tech-driven complexity; they push the work beyond what's possible with traditional methods.</p>
          <p>I'm available for commissions, and I have one-of-a-kind single-edition framed prints available for purchase.</p>
          <a
            class="contact"
            href="mailto:kellymilligan.art@gmail.com"
            target="_blank"
            @mouseenter="onMouseEnterContact"
            @touchstart="onMouseEnterContact"
            :class="{ 'is-animating-hover': isHoverAnimationRunningContact }"
          >
            <strong>CONTACT</strong>
          </a>
        </div>
        <div class="credit">
          <p>
            Design direction by The Great
            <a href="http://stevemcgeorge.com/" target="_blank">
              <span>Steve McGeorge</span>
            </a>.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>

export default {
  name: 'About',
  props: {
    scrollToTop: {
      type: Function,
      required: true
    },
    setScrollTop: {
      type: Function,
      required: true
    }
  },
  components: {},
  computed: {},
  data() {
    return {
      isMounted: false,
      isHoverAnimationRunningContact: false,
      hoverAnimationTimerContact: null
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setScrollTop( 0 )
    next()
  },
  mounted() {
    this.scrollToTop( true )
    this.isMounted = true
  },
  methods: {
    stageAfterEnter() {
      this.$store.commit( 'setScrollLocked', false )

      /*
        Defer an additional unlock, sometimes when navigating the scroll lock is
        called after this callback for some reason...
      */
      setTimeout(() => {
        this.$store.commit( 'setScrollLocked', false )
      }, 0)
    },
    onMouseEnterContact() {
      if ( this.isHoverAnimationRunningContact ) return
      this.isHoverAnimationRunningContact = true
      clearTimeout( this.hoverAnimationTimerContact )
      this.hoverAnimationTimerContact = setTimeout( () => { this.isHoverAnimationRunningContact = false }, 600 )
    },
  }
}

</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';

  @keyframes animate-in {
    from {
      opacity: 1;
      transform: translate3d( 0, 0.75vw, 0 );
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  $base: 0.8;
  $flow: $base + 2.05;

  .About {

    article {

      position: relative;

      padding: rem( 160 ) 16vw 0 15vw;

      @include breakpoint( mobile ) {

        padding: rem( 140 ) rem( 25 ) 0 rem( 25 );

      }

    }

    h1 {

      margin-top: 0;
      margin-bottom: rem( 120 );

      @include fontBlack();
      font-size: 9vw;
      line-height: 0.87;

      @include breakpoint( mobile ) {

        margin-bottom: rem( 120 );

        font-size: 11.5vw;
        line-height: 0.93;

      }

      strong {

        display: block;

      }

      span {

        display: inline-block;

        opacity: 0;
        animation: animate-in forwards 1.2s $ease-out-quart;

        &.fine {      animation-delay: #{$base + 0.0  }s; }
        &.art  {      animation-delay: #{$base + 0.07 }s; }

        &.comma {     animation-delay: #{$base + 1.13 }s; }

        &.made {      animation-delay: #{$base + 1.2  }s; }
        &.with {      animation-delay: #{$base + 1.4  }s; }

        &.code {      animation-delay: #{$base + 1.65 }s; }

        &.fullstop {  animation-delay: #{$base + 1.9 }s; }

        &.comma {

          position: relative;
          left: -1.2vw;
          top: 0;

        }

        &.fullstop {

          position: relative;

          display: inline-block;
          width: 0;
          height: 0;

          color: transparent;

          &::after {

            content: '';

            position: absolute;
            left: 0.10em;
            bottom: 0;

            display: block;
            width: 0.27em;
            height: 0.27em;

            background: $color-black;
            border-radius: 50%;

          }
        }

      }

    }

    .biography {

      position: relative;

      display: flex;
      flex-wrap: wrap;
      align-items: top;
      justify-content: space-between;

      padding-bottom: rem( 280 );

    }

    .primary {

      width: rem( 450 );
      margin-right: 3vw;
      margin-bottom: rem( 50 );
      flex-shrink: 0;

      @include fontMedium();
      font-size: rem( 19 );
      letter-spacing: 0.01em;
      line-height: 1.4;
      text-transform: uppercase;

      opacity: 0;
      animation: animate-in forwards 1.2s #{$flow}s $ease-out-quart;

      @include breakpoint( mobile ) {

        width: 100%;
        margin-right: 0;
        margin-bottom: rem( 60 );

        font-size: 3.6vw;
        line-height: 1.6;

      }

      p {

        margin: 0;

      }

    }

    .secondary {

      position: relative;

      flex-shrink: 0;
      width: rem( 360 );
      margin-right: 5vw;

      @include breakpoint( mobile ) {

        width: 100%;
        margin-right: 0;

      }

      hr {

        display: block;
        width: rem( 55 );
        height: rem( 3 );
        margin: rem( 5 ) 0 rem( 180 );

        background: $color-white;
        border: 0;

        opacity: 0;
        animation: animate-in forwards 1.2s #{$flow + 0.2}s $ease-out-quart;

        @include breakpoint( mobile ) {

          margin: 0 0 rem( 40 );

        }

      }

      p {

        margin: 0 0 rem( 25 );

        opacity: 0;
        animation: animate-in forwards 1.2s #{$flow + 0.4}s $ease-out-quart;

        a {

          display: inline-block;

          @include fontMedium();
          text-decoration: none;

          span {

            display: block;

          }

          &:hover {

            span {

              @keyframes secondary-link-hover {
                0% {
                  color: rgba( $color-white, 0.8 );
                  transform: translate3d( 0, rem( 2 ), 0 );
                }
                20% {
                  color: rgba( $color-white, 0.8 );
                }
                20.1% {
                  color: $color-white;
                }
                100% {
                  color: $color-white;
                  transform: none;
                }
              }

              animation: secondary-link-hover both 0.8s $ease-out-quart;

            }

          }

        }
      }

      a.contact {

        position: relative;

        display: inline-block;
        margin-top: rem( 50 );

        @include fontBlack();
        font-size: rem( 37 );
        letter-spacing: 0.01em;
        line-height: 1;
        text-decoration: none;

        opacity: 0;
        animation: animate-in forwards 1.2s #{$flow + 0.4}s $ease-out-quart;

        @include breakpoint( mobile ) {

          font-size: rem( 28 );

        }

        strong {

          display: block;
          padding-bottom: rem( 10 );

          @include breakpoint( mobile ) {

            padding-bottom: rem( 8 );

          }

        }

        &::after {

          content: '';

          position: absolute;
          left: 0;
          top: 100%;

          width: 100%;
          height: rem( 9 );

          background: $color-white;

          @include breakpoint( mobile ) {

            height: rem( 7 );

          }

        }

        &.is-animating-hover {

          strong {

            @keyframes contact-link-hover {
              0% {
                color: rgba( $color-white, 0.8 );
                transform: translate3d( 0, rem( 6 ), 0 );
              }
              20% {
                color: rgba( $color-white, 0.8 );
              }
              20.1% {
                color: $color-white;
              }
              100% {
                color: $color-white;
                transform: none;
              }
            }

            animation: contact-link-hover both 0.8s $ease-out-quart;

          }

          &::after {

            @keyframes contact-link-underline-hover {
              0% {
                background: rgba( $color-white, 0.8 );
                transform: translate3d( 0, rem( 6 ), 0 );
              }
              20% {
                background: rgba( $color-white, 0.8 );
              }
              20.1% {
                background: $color-white;
              }
              100% {
                background: $color-white;
                transform: none;
              }
            }

            animation: contact-link-underline-hover both 0.8s 0.08s $ease-out-quart;

          }

        }

      }

    }

    .credit {

      position: absolute;
      left: 0;
      bottom: rem( 34 );

      @media only screen and (min-width: 1680px) {
        bottom: rem( 41 );
      }

      @media only screen and (min-width: 2200px) {
        bottom: rem( 50 );
      }

      @include breakpoint( mobile ) {
        bottom: rem( 20 );

      }

      p {

        margin: 0;

      }

      a {

        display: inline-block;

        @include fontMedium();
        text-decoration: none;

        span {

          display: block;

        }

        &:hover {

          span {

            @keyframes credit-link-hover {
              0% {
                color: rgba( $color-white, 0.8 );
                transform: translate3d( 0, rem( 3 ), 0 );
              }
              20% {
                color: rgba( $color-white, 0.8 );
              }
              20.1% {
                color: $color-white;
              }
              100% {
                color: $color-white;
                transform: none;
              }
            }

            animation: credit-link-hover both 0.8s $ease-out-quart;

          }

        }

      }

    }

  }

</style>
